import React from 'react';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/seo';
import Layout from '@layouts/default-layout';
import Section from '@components/section';
import { RichTextField } from '@prismicio/types';
import Text from '@components/text';
import { withPrismicUnpublishedPreview, UnknownRecord } from 'gatsby-plugin-prismic-previews';
import {
  Content,
  StyledText,
  StyledButton,
  StyledWrapper,
  StyledImage,
} from '../styles/contact.styled';

interface NotFoundPageProps extends PageProps<UnknownRecord> {
  data: {
    prismicPage404: {
      data: {
        title?: {
          text: string;
        };
        subheading?: {
          richText: RichTextField;
        };
        image?: {
          alt: string;
          url: string;
        };
        link: {
          url: string;
          document: {
            uid: string;
          };
        };
        link_label?: string;
      };
    };
  };
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({
  data: {
    prismicPage404: {
      data: { title, subheading, image, link, link_label },
    },
  },
}: NotFoundPageProps): JSX.Element => (
  <Layout isAlternativeHeaderProvided={false} isAlternativeFooterProvided>
    <SEO title="404: Not found" />
    <Section backgroundColor="white">
      <StyledWrapper>
        <Content>
          <Text type="title" stringText={title?.text} />
          <StyledText type="subheading" richText={subheading?.richText} />
          {(Boolean(link?.url) || Boolean(link?.document?.uid)) && (
            <StyledButton to={link?.url || `/${link?.document.uid}`}>{link_label}</StyledButton>
          )}
        </Content>
        <StyledImage src={image?.url} />
      </StyledWrapper>
    </Section>
  </Layout>
);

export default withPrismicUnpublishedPreview(NotFoundPage);

export const query = graphql`
  query Page404Query {
    prismicPage404 {
      ...Page404Fragment
    }
  }
`;
