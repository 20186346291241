import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { Button } from '@styles/button.styled';
import { generateScales } from '@helpers/generate-media-queries';
import Wrapper from '@components/wrapper.styled';
import Text from '@components/text';

export const Content = styled.div`
  position: relative;
  z-index: 2;
  max-width: 34rem;
  ${generateScales('padding-top', '3.5rem', '8rem')}
  ${generateScales('padding-bottom', '2.5rem', '8rem')}

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: 30rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 24rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 100%;
      max-width: unset;
      text-align: center;
    }
  `}
`;

export const StyledText = styled(Text)`
  display: block;
  ${generateScales('margin-top', '1.25rem', '1.5rem')}
`;

export const StyledButton = styled(Link)`
  ${Button}
  ${generateScales('margin-top', '2rem', '3rem')}

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin: 0 auto;
      ${generateScales('margin-top', '2rem', '3rem')}
    }
  `}
`;

export const StyledWrapper = styled(Wrapper)`
  position: relative;
`;

export const StyledImage = styled.img`
  position: absolute;
  right: 0;
  top: 50%;
  height: 100%;
  width: auto;
  transform: translateY(-50%) translateX(8rem);
  z-index: 1;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      height: 75%;
    }
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;
